<template>
  <div class="topPadding">
    <el-tabs v-model="activeName" @tab-click="handleClick">

      <el-tab-pane label="虚拟人配置" name="first">
        <div class="app-container">
          <el-form ref="form" :model="virtualForm" :rules="rules" size="small" label-width="300px" label-position="left">
            <el-form-item label="系统小精灵">
              <el-select v-model="virtualForm.virtual_elf_uid" placeholder="请选择小精灵">
                <el-option v-for="item in virtualList" :key="item.uid" :label="item.nickname + '(' + item.uid + ')'"
                  :value="item.uid.toString()">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="doSubmit(virtualForm)">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Chat GPT基础设置" name="second">
        <div class="app-container">
          <el-form ref="form" :model="gptForm" :rules="rules" size="small" label-width="300px" label-position="left">
            <el-form-item label="gpt回复用到的上下文长度">
              <el-input v-model="gptForm.gpt_history_limit" style="width: 370px" />
            </el-form-item>

            <el-form-item label="每次回复时，查询当前时间往前推N天的历史记录">
              <el-input v-model="gptForm.gpt_im_history_day_limit" style="width: 370px" />
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="doGptSubmit(gptForm)">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Chat GPT聊天设置" name="three">
        <div class="app-container">
          <el-form ref="form" :model="gptChatForm" :rules="rules" size="small" label-width="300px" label-position="left">
            <el-form-item label="是否开启Chat GPT的敏感词过滤">
              <el-radio v-model="gptChatForm.enable_gpt_sensitive_word" label="true">开启</el-radio>
              <el-radio v-model="gptChatForm.enable_gpt_sensitive_word" label="false">关闭</el-radio>
            </el-form-item>
            <el-divider />

            <el-form-item label="是否开启Chat GPT超时柔性结束会话回复功能">
              <el-radio v-model="gptChatForm.gpt_friendly_ending_prompt_enable" label="true">开启</el-radio>
              <el-radio v-model="gptChatForm.gpt_friendly_ending_prompt_enable" label="false">关闭</el-radio>
            </el-form-item>
            <el-form-item label="Chat GPT超时柔性结束会话时间（分钟）">
              <el-input v-model="gptChatForm.gpt_friendly_ending_interval" style="width: 370px" />
            </el-form-item>

            <el-form-item label="Chat GPT超时柔性结束会话Prompt，多个请换行">
              <el-input type="textarea" rows="6" v-model="gptChatForm.gpt_friendly_ending_prompts_str"
                style="width: 370px" />
            </el-form-item>
            <el-divider />

            <el-form-item label="用户发送消息合并给GPT的间隔时间（秒）">
              <el-input v-model="gptChatForm.gpt_chat_second_time" style="width: 370px" />
            </el-form-item>

            <el-form-item label="是否开启对话增加时间线功能">
              <el-radio v-model="gptChatForm.gpt_chat_enable_im_timeline" label="true">开启</el-radio>
              <el-radio v-model="gptChatForm.gpt_chat_enable_im_timeline" label="false">关闭</el-radio>
            </el-form-item>

            <el-form-item label="触发时间线时给GPT的时间线文本（时间线用 {timeline} 代替）">
              <el-input v-model="gptChatForm.gpt_timeline_say" style="width: 370px" />
            </el-form-item>
            <el-divider />

            <el-form-item label="gpt回复长文分隔长度">
              <el-input v-model="gptChatForm.gpt_reply_split_char_limit" style="width: 370px" />
            </el-form-item>

            <el-form-item label="gpt回复分割后每段回复延迟发送时间">
              <el-input type="number" v-model="gptChatForm.gpt_delay_reply_second_min" style="width: 150px">
                <template slot="append">秒</template>
              </el-input>
              <span> ~ </span>
              <el-input type="number" v-model="gptChatForm.gpt_delay_reply_second_max" style="width: 150px">
                <template slot="append">秒</template>
              </el-input>
            </el-form-item>

            <el-form-item label="">
              <span slot="label">gpt回复长文分割符（回车换行，一行一个字符，只能区分字符）
                <el-popover placement="right" width="500" trigger="click">
                  <div>
                    <el-input type="textarea" rows="6" placeholder="请输入测试文本" @input="testSeparating"
                      v-model="testSeparatingInput" />

                    <el-tag style="margin-top: 15px" v-for="tag in testSeparatingResult" :key="tag">
                      {{ tag }}
                    </el-tag>

                  </div>
                  <el-button type="text" @click="testSeparating" slot="reference">测试一下？</el-button>
                </el-popover>
              </span>
              <el-input type="textarea" rows="6" v-model="gptChatForm.gpt_reply_split_delimiters_str"
                style="width: 370px" />


            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="doGptChatSubmit(gptChatForm)">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

      <!-- <el-tab-pane label="客户端配置" name="five">
        <div class="app-container">
          <el-form
              ref="form"
              :model="appForm"
              :rules="rules"
              size="small"
              label-width="300px"
              label-position="left"

          >
            <el-form-item label="iOS客户端版本">
              <el-input v-model="appForm.app_ios_version" style="width: 370px" />
            </el-form-item>
            <el-form-item label="Android客户端版本">
              <el-input v-model="appForm.app_android_version" style="width: 370px" />
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="doSubmit(appForm)">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testSeparatingInput: '',
      testSeparatingResult: [],
      gptReplySplitDelimitersInputInputVisible: false,
      gptReplySplitDelimitersInput: "",
      url: "",
      activeName: "first",
      dataList: [],
      gptForm: {
        gpt_history_limit: '',
        gpt_im_history_day_limit: '',
        gpt_open_ai_api_keys: '',
        gpt_open_ai_api_keys_str: '',
      },
      gptChatForm: {

        gpt_timeline_say: '',
        enable_gpt_sensitive_word: false,
        gpt_chat_second_time: '',
        gpt_reply_split_delimiters: [],
        gpt_reply_split_delimiters_str: '',
        gpt_reply_split_char_limit: 100,
        gpt_delay_reply_second_min: 0,
        gpt_delay_reply_second_max: 0,
        gpt_friendly_ending_prompts: '',
        gpt_friendly_ending_prompts_str: '',
        gpt_friendly_ending_interval: 0,
        gpt_friendly_ending_prompt_enable: false,
        gpt_chat_enable_im_timeline: true,
      },
      // appForm: {
      //   app_ios_version: '',
      //   app_android_version: ''
      // },
      virtualForm: {
        virtual_elf_uid: ''
      },
      rules: {},
      virtualList: [],
    };
  },
  created() {
    this.getVirtualUserList()
    this.getDataList();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
    },
    // 获取数据列表
    getDataList() {
      this.url = this.$http.adornUrl(
        `/sys/oss/upload?token=${this.$cookie.get("token")}`
      );
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.$http({
        url: this.$http.adornUrl("/sys/config/list"),
        method: "get",
        params: this.$http.adornParams({
          page: 1,
          limit: 1000,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          data.page.list.map(function (key, value) {
            const keyName = key.paramKey;
            const newValue = key.paramValue;
            if (keyName in that.gptForm) {
              that.gptForm[keyName] = newValue;
            } if (keyName in that.gptChatForm) {
              that.gptChatForm[keyName] = newValue;
            } else if (keyName in that.virtualForm) {
              that.virtualForm[keyName] = newValue;
            }
          });

          this.gptChatForm.gpt_chat_second_time = parseInt(this.gptChatForm.gpt_chat_second_time);
          this.gptChatForm.gpt_reply_split_delimiters_str = JSON.parse(this.gptChatForm.gpt_reply_split_delimiters).join("\n");
          this.gptForm.gpt_open_ai_api_keys_str = JSON.parse(this.gptForm.gpt_open_ai_api_keys).join("\n");
          this.gptChatForm.gpt_friendly_ending_prompts_str = JSON.parse(this.gptChatForm.gpt_friendly_ending_prompts).join("\n");
          this.gptChatForm.gpt_delay_reply_second_min = parseInt(this.gptChatForm.gpt_delay_reply_second_min);
          this.gptChatForm.gpt_delay_reply_second_max = parseInt(this.gptChatForm.gpt_delay_reply_second_max);
          this.gptChatForm.gpt_reply_split_char_limit = parseInt(this.gptChatForm.gpt_reply_split_char_limit)
          this.gptChatForm.gpt_friendly_ending_interval = parseInt(this.gptChatForm.gpt_friendly_ending_interval)

        }
      });
    },
    testSeparating() {
      this.$http({
        url: this.$http.adornUrl(`/admin/common/testSeparating`),
        method: "post",
        data: {
          content: this.testSeparatingInput,
          charLimit: this.gptChatForm.gpt_reply_split_char_limit,
          delimiters: this.gptChatForm.gpt_reply_split_delimiters_str.split("\n")
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.testSeparatingResult = data.result
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    doSubmit(form) {
      this.$http({
        url: this.$http.adornUrl(`/sys/config/updateBatch`),
        method: "post",
        data: form,
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: "设置成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.$emit("refreshDataList");
            },
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    doGptChatSubmit(form) {
      if (form.gpt_delay_reply_second_min > form.gpt_delay_reply_second_max) {
        this.$message.error("时间间隔设置错误");
        return
      }
      form.gpt_reply_split_delimiters = form.gpt_reply_split_delimiters_str.split("\n").filter(item => item !== '');
      form.gpt_friendly_ending_prompts = form.gpt_friendly_ending_prompts_str.split("\n").filter(item => item !== '');
      this.doSubmit(form)
    },
    doGptSubmit(form) {
      form.gpt_open_ai_api_keys = form.gpt_open_ai_api_keys_str.split("\n").filter(item => item !== '');
      this.doSubmit(form)
    },
    // 表单提交
    // dataFormSubmit() {
    //   this.$refs["dataForm"].validate((valid) => {
    //     if (valid) {
    //       this.$http({
    //         url: this.$http.adornUrl(
    //           `/sys/config/${!this.dataForm.id ? "save" : "update"}`
    //         ),
    //         method: "post",
    //         data: this.$http.adornData({
    //           id: this.dataForm.id || undefined,
    //           paramKey: this.dataForm.paramKey,
    //           paramValue: this.dataForm.paramValue,
    //           remark: this.dataForm.remark,
    //         }),
    //       }).then(({ data }) => {
    //         if (data && data.code === 0) {
    //           this.$message({
    //             message: "操作成功",
    //             type: "success",
    //             duration: 1500,
    //             onClose: () => {
    //               this.visible = false;
    //               this.$emit("refreshDataList");
    //             },
    //           });
    //         } else {
    //           this.$message.error(data.msg);
    //         }
    //       });
    //     }
    //   });
    // },

    // handleClose(tag) {
    //   this.gptChatForm.gpt_reply_split_delimiters.splice(this.gptChatForm.gpt_reply_split_delimiters.indexOf(tag), 1);
    // },
    // handleInputConfirm() {
    //   const inputValue = this.gptReplySplitDelimitersInput;
    //   if (inputValue) {
    //     this.gptChatForm.gpt_reply_split_delimiters.push(inputValue);
    //   }
    //   this.gptReplySplitDelimitersInputInputVisible = false;
    //   this.gptReplySplitDelimitersInput = "";
    // },
    // showInput() {
    //   this.gptReplySplitDelimitersInputInputVisible = true;
    //   this.$nextTick(_ => {
    //     this.$refs.saveTagInput.$refs.input.focus();
    //   });
    // },
    getVirtualUserList() {
      this.$http({
        url: this.$http.adornUrl(`/admin/virtual/all`),
        method: 'get'
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.virtualList = data.result
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err);
      })
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px 20px 45px 20px;
}

.topPadding {
  padding-left: 30px;
}

.formInfo {
  line-height: 0px;
  color: #999999;
  font-size: 12px;
}

.notice {
  line-height: 0px;
  color: #656161;
}

.avatar-uploader .el-upload {
  border: 3px dashed #979494;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.avatar2 {
  width: 200px;
  height: 80px;
  display: block;
}

.c-mr10 {
  margin-right: 10px !important;
}

.el-tag {
  white-space: normal;
  height: auto;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
}

.el-divider {
  background-color: #dcdfe6;
  position: relative;
}

.el-divider__text {
  position: absolute;
  background-color: #fff;
  padding: 0 20px;
  font-weight: 500;
  color: #303133;
  font-size: 14px;
}

.el-divider__text.is-right {
  right: 20px;
  transform: translateY(-50%);
}
</style>
